// @nebular theming framework
@import '@nebular/theme/styles/theming';
// @nebular out of the box themes
@import '@nebular/theme/styles/themes/corporate';
@import '@nebular/theme/styles/themes/dark';

@font-face {
  font-family: InnomoticsHafferSQ;
  src: url('../../../assets/font/InnomoticsHafferSQ-Regular.woff'), url('../../../assets/font/InnomoticsHafferSQ-Regular.otf'),
    url('../../../assets/font/InnomoticsHafferSQ-Regular.woff2'), url('../../../assets/font/InnomoticsHafferSQ-Regular.ttf');
}

// light mode
$nb-themes: nb-register-theme(( // Colors

  // colors
  // ___________________________________________________
  color-primary-100: #E1F000,
  color-primary-200: #08191F,
  color-primary-300: #FFFFFF,
  color-primary-hover: color-primary-200,
  color-primary-hover-border: color-primary-100,
  color-primary-active-border: color-primary-100,
  color-primary-focus-border: color-primary-100,
  color-basic-hover-border: color-basic-200,
  color-basic-input-hover-border: rgba(64, 84, 91, 1),

  text-control-color: color-primary-300,
  // color-primary-400: #E1F000,
  // color-primary-500: #E1F000,
  // color-primary-600: #E1F000,
  // color-primary-700: #E1F000,
  // color-primary-800: #E1F000,
  // color-primary-900: #E1F000,

  color-primary-transparent-100: rgba(8, 25, 31, 0.1), // 10% color-primary-200
  color-primary-transparent-200: rgba(8, 25, 31, 0.2), // 20% color-primary-200
  color-primary-transparent-500: rgba(8, 25, 31, 0.5), // 50% color-primary-200
  color-primary-transparent-600: rgba(8, 25, 31, 0.6), // 60% color-primary-200
  color-primary-transparent-800: rgba(8, 25, 31, 0.8), // 80% color-primary-200

  color-primary-100-transparent-700: rgba(255, 255, 255, 0.7),

  color-basic-100: #E4EAED,
  color-basic-200: #CAD5DA,
  color-basic-300: #B2C1C7,
  color-basic-400: #9AACB4,
  color-primary-disabled: color-basic-400,
  color-basic-500: #657880,
  color-basic-600: #53676F,
  color-basic-active: color-basic-600,
  color-basic-700: #2A3B40,
  // color-basic-800: #E1F000,
  // color-basic-900: #E1F000,

  color-success-100: #BAFFA9,
  color-success-200: #01823D,
  color-success-focus: color-success-200,
  color-success-hover: color-success-200,
  color-success-default: color-success-200,

  color-warning-100: #FFCC49,
  color-warning-200: #FF853F,
  color-warning-focus: color-warning-200,
  color-warning-hover: color-warning-200,
  color-warning-default: color-warning-200,

  color-danger-100: #FF9B8D,
  color-danger-200: #CB0E0E,
  color-danger-focus: color-danger-200,
  color-danger-hover: color-danger-200,
  color-danger-default: color-danger-200,

  color-info-500: #1491EB,
  color-info-default: color-info-500,

  color-primary-default: color-primary-200,
  color-primary-default-border: transparent,
  color-primary-active: color-basic-400,
  color-primary-transparent-active-border: color-basic-400,
  color-primary-transparent-focus-border: color-basic-400,
  // mapping ?
  // --internal-200: #C0A1F9,
  // --internal-100: #9747FF,
  color-active-100: #D2E100,
   --internal-200: #C0A1F9,
   --internal-100: #9747FF,
   --active-100: #D2E100,

  border-basic-color-3: color-primary-transparent-200,

  background-basic-color-1: color-primary-300,
  background-basic-color-2: color-basic-100,
  background-alternative-color-3: color-basic-700,
  text-alternate-color: color-primary-300,

  scrollbar-color: color-primary-transparent-200,
  scrollbar-background-color: color-primary-transparent-100,
  scrollbar-width: 5px,
  overlay-backdrop-background-color: color-primary-transparent-600,


  // font
  // ___________________________________________________
  innomotics-font: unquote('InnomoticsHafferSQ, sans-serif'),
  font-family-primary: innomotics-font,
  font-family-secondary: innomotics-font,
  text-basic-color: color-primary-200,
  // forms
  form-field-addon-basic-highlight-text-color: color-primary-200,
  form-field-addon-basic-text-color: color-primary-200,

  // from figma spec
  interaction-hover: #40545B,

  // text
  text-primary-color: color-primary-200,
  text-primary-hover-color: color-primary-200,
  text-primary-focus-color: color-primary-200,
  text-disabled-color: color-basic-400,

  text-body-1-font-size: 24px,
  text-body-1-font-weight: 400,
  text-body-1-line-height: 37px,
  text-body-2-font-size: 20px,
  text-body-2-font-weight: 400,
  text-body-2-line-height: 31px,
  text-body-3-font-size: 16px,
  text-body-3-font-weight: 400,
  text-body-3-line-height: 25px,
  text-body-4-font-size: 14px,
  text-body-4-font-weight: 400,
  text-body-4-line-height: 22px,
  text-body-5-font-size: 12px,
  text-body-5-font-weight: 400,
  text-body-5-line-height: 19px,

  // theme fonts
  text-heading-1-font-size: 60px,
  text-heading-1-font-weight: 700,
  text-heading-1-line-height: 88px,
  text-heading-2-font-size: 48px,
  text-heading-2-font-weight: 700,
  text-heading-2-line-height: 58px,
  text-heading-3-font-size: 32px,
  text-heading-3-font-weight: 700,
  text-heading-3-line-height: 42px,
  text-heading-4-font-size: 24px,
  text-heading-4-font-weight: 800,
  text-heading-4-line-height: 31px,
  text-heading-5-font-size: 20px,
  text-heading-5-font-weight: 800,
  text-heading-5-line-height: 26px,
  text-heading-6-font-size: 16px,
  text-heading-6-font-weight: 800,
  text-heading-6-line-height: 21px,

  text-subtitle-font-size: text-heading-5-font-size,
  text-subtitle-font-weight: text-heading-5-font-weight,
  text-subtitle-line-height: text-heading-5-line-height,
  text-subtitle-2-font-size: text-heading-6-font-size,
  text-subtitle-2-font-weight: text-heading-6-font-weight,
  text-subtitle-2-line-height: text-heading-6-line-height,

  text-paragraph-font-size: text-body-3-font-size,
  text-paragraph-font-weight: text-body-3-font-weight,
  text-paragraph-line-height: text-body-3-line-height,

  text-caption-font-size: text-body-4-font-size,
  text-caption-font-weight: 800,
  text-caption-line-height: text-body-4-line-height,
  text-caption-font-family: unquote('InnomoticsHafferSQ, sans-serif, upper-case'),

  text-button-tiny-font-size: text-body-5-font-size,
  text-button-tiny-line-height: text-body-5-font-size,
  text-button-medium-font-size: text-body-3-font-size,
  text-button-medium-line-height: text-body-3-font-size,
  text-button-font-weight: 800,

  // theme
  // ___________________________________________________
  divider-width: 0px,
  divider-style: none,
  divider-color: transparent,
  outline-color: transparent,
  border-radius: 0px,
  // scrollbar-width: 0px,

  // Components
  // ___________________________________________________
  // layout
  layout-background-color: color-basic-100,
  layout-padding: 36px 24px 24px,
  // layout-scrollbar-background-color: color-primary-300,
  // layout-scrollbar-color: color-primary-200,

  // layout header
  header-background-color: color-primary-100,
  header-shadow: none,
  header-height: 72px,

  // card
  card-border-width: 0px,
  card-border-style: none,
  card-margin-bottom: 24px,
  card-border-color: transparent,
  card-background-color: color-primary-300,


  // card-scrollbar-color: color-primary-transparent-200,
  // card-scrollbar-background-color: color-primary-transparent-100,
  // card-scrollbar-width: 5px,

  // card-header
  card-header-text-font-size: text-heading-5-font-size,
  card-text-font-weight: text-heading-5-font-weight,

  // sidebar
  sidebar-background-color: color-primary-300,
  sidebar-width: 320px,
  sidebar-width-compact: 4rem,
  sidebar-padding: 0px,
  menu-item-padding: 16px,
  menu-background-color: color-primary-300,
  menu-item-active-background-color: color-basic-200,
  menu-submenu-item-active-background-color: color-basic-200,
  menu-item-divider-color: color-basic-200,
  menu-item-divider-width: 2px,
  menu-item-divider-style: solid,
  menu-text-color: color-basic-600,
  menu-submenu-padding: 0px,
  menu-submenu-margin: 0px,

  // bagde
  badge-border-radius: 2rem,
  badge-basic-background-color: color-primary-200,
  badge-basic-text-color: color-primary-100,
  badge-padding: 8px 4px,

  // user
  user-picture-box-background-color: color-primary-200,
  user-picture-box-border-width: 0px,
  user-initials-text-color: color-primary-300,
  user-medium-height: 40px,
  user-medium-width: 40px,
  user-initials-text-font-weight: 800,
  // accordion
  accordion-border-radius: 0px,

  // selects
  option-list-border-with: 0,
  option-background-color: color-basic-700,
  option-focus-color: color-basic-100,
  option-focus-background-color: color-basic-600,
  option-text-color: color-basic-100,
  option-focus-text-color: color-basic-100,
  option-medium-text-font-size: text-body-3-font-size,
  option-medium-text-font-weight: 400,
  option-hover-text-color: color-basic-100,
  option-hover-background-color: color-basic-600,
  option-selected-focus-background-color: color-basic-700,
  option-selected-focus-text-color: color-primary-100,
  option-selected-hover-text-color: color-primary-100,
  option-selected-hover-background-color: color-basic-600,
  option-selected-text-color: color-primary-100,
  select-medium-text-font-size: text-body-3-font-size,
  select-medium-text-line-height: input-medium-text-line-height,
  select-medium-max-width: 30rem,
  select-outline-border-style: solid,
  select-outline-border-width: 1px,
  select-outline-color: color-primary-200,
  select-outline-basic-text-color: color-primary-200,
  select-outline-basic-background-color: color-primary-300,
  select-outline-basic-hover-border-color: color-primary-200,
  select-outline-basic-hover-background-color: color-primary-300,
  select-outline-basic-placeholder-text-color: color-primary-200,

  // inputs
  input-medium-text-font-weight: normal,
  input-medium-text-font-size: 16px,
  input-medium-text-line-height: 24.8px,
  input-medium-text-placeholder-line-height: 24.8px,
  input-basic-background-color: color-primary-300,
  input-basic-border-color: color-basic-400,
  input-basic-disabled-background-color: color-basic-100,
  input-basic-disabled-text-color: text-disabled-color, // color-primary-disabled,
  input-basic-disabled-placeholder-text-color: color-basic-200,
  input-basic-active-background-color: color-primary-300,
  input-basic-focus-background-color: color-primary-300,
  input-basic-focus-border-color: color-basic-input-hover-border,
  input-basic-focus-and-hover-background-color: color-primary-300,
  input-basic-focus-and-hover-border-color: color-basic-input-hover-border,
  input-basic-hover-background-color: color-primary-300,
  input-basic-hover-border-color: color-basic-input-hover-border,
  input-danger-background-color: color-primary-300,
  input-danger-active-background-color: color-primary-300,
  input-danger-focus-background-color: color-primary-300,
  input-danger-hover-background-color: color-primary-300,
  input-success-background-color: color-primary-300,
  input-success-active-background-color: color-primary-300,
  input-success-focus-background-color: color-primary-300,
  input-success-hover-background-color: color-primary-300,
  input-border-style: solid,
  input-rectangle-border-radius: 0rem,
  input-outline-color: color-basic-400,
  input-outline-width: 1.5px,

  // tabs
  tabset-border-radius: 0px,
  tabset-background-color: transparent,
  tabset-tab-background-color: transparent,
  tabset-tab-text-color: color-basic-600,
  tabset-tab-text-font-size: text-body-3-font-size,
  tabset-tab-text-font-weight: 790,
  tabset-tab-text-line-height: text-body-3-line-height,
  tabset-tab-underline-width: 1px,
  tabset-tab-underline-color: color-primary-transparent-200,
  tabset-tab-active-background-color: transparent,
  tabset-tab-text-transform: capitalize,
  tabset-tab-focus-text-color: text-primary-color,
  tabset-tab-focus-underline-color: text-primary-color,
  tabset-tab-hover-text-color: color-basic-700,
  tabset-tab-hover-underline-color: color-basic-700,
  tabset-content-padding: 0px,

  route-tabset-border-radius: 0px,
  route-tabset-background-color: transparent,
  route-tabset-tab-background-color: transparent,
  route-tabset-tab-text-color: color-basic-600,
  route-tabset-tab-text-font-size: text-body-3-font-size,
  route-tabset-tab-text-font-weight: 790,
  route-tabset-tab-text-line-height: text-body-3-line-height,
  route-tabset-tab-underline-width: 1px,
  route-tabset-tab-underline-color: color-primary-transparent-200,
  route-tabset-tab-active-background-color: transparent,
  route-tabset-tab-text-transform: capitalize,
  route-tabset-tab-focus-text-color: text-primary-color,
  route-tabset-tab-focus-underline-color: text-primary-color,
  route-tabset-tab-hover-text-color: color-basic-700,
  route-tabset-tab-hover-underline-color: color-basic-700,

  // calendar
  calendar-border-width: 0px,
  calendar-border-style: none,
  calendar-background-color: color-basic-700,

  // calendar text
  calendar-text-color: color-primary-300,
  calendar-navigation-text-color: color-primary-300,
  calendar-text-font-size: text-body-3-font-size,
  calendar-text-font-weight: 430,

  calendar-weeknumber-text-color: color-primary-300,
  calendar-weeknumber-text-font-weight: 790,
  calendar-weeknumber-text-font-size: text-body-5-font-size,

  calendar-weekday-text-color: color-primary-300,
  calendar-weekday-text-font-weight: 790,
  calendar-weekday-text-font-size: text-body-5-font-size,

  // cells
  calendar-cell-hover-background-color: color-basic-600,
  calendar-cell-hover-border-color: color-basic-600,
  calendar-cell-hover-text-color: color-primary-300,
  calendar-cell-active-background-color: color-primary-100,
  calendar-cell-active-border-color: color-primary-100,

  calendar-cell-today-text-color: color-primary-300,
  calendar-cell-today-background-color: transparent,
  calendar-cell-today-border-color: color-primary-300,
  calendar-cell-today-hover-background-color: color-basic-600,
  calendar-cell-today-hover-border-color: color-primary-300,
  calendar-cell-today-in-range-border-color: color-primary-300,
  // calendar-cell-today-selected-background-color: ,
  calendar-cell-today-selected-border-color: color-primary-300,

  calendar-cell-selected-background-color: color-basic-600,
  calendar-cell-selected-border-color: color-basic-600,
  calendar-cell-selected-hover-background-color: color-basic-200,
  calendar-cell-selected-hover-border-color: color-basic-200,
  calendar-cell-selected-hover-text-color: color-primary-200,
  calendar-cell-selected-active-border-color: transparent,

  // date picker
  datepicker-border-width: 0px,
  datepicker-border-style: none,
  datepicker-background-color: color-basic-700,

  // time Picker
  timepicker-border-width: 0px,
  timepicker-border-style: none,
  timepicker-border-color: transparent,
  timepicker-scrollbar-color: color-primary-300,
  timepicker-container-width: 15rem,
  timepicker-scrollbar-width: 0px,

  timepicker-cell-text-color: color-primary-300,
  timepicker-cell-text-font-size: text-body-3-font-size,
  timepicker-cell-text-font-weight: text-body-3-font-weight,
  timepicker-cell-text-line-height: text-body-3-line-height,

  timepicker-header-cell-text-color: color-primary-300,
  timepicker-header-cell-text-font-size: text-body-5-font-size,
  timepicker-header-cell-text-line-height: text-body-5-line-height,
  timepicker-header-cell-text-font-weight: 790,

  timepicker-cell-hover-text-color: color-primary-300,
  timepicker-cell-hover-text-font-size: text-body-5-font-size,
  timepicker-cell-hover-text-line-height: text-body-5-line-height,
  timepicker-cell-hover-background-color: color-basic-600,

  timepicker-cell-active-text-color: color-primary-200,
  timepicker-cell-active-text-font-size: text-body-5-font-size,
  timepicker-cell-active-text-line-height: text-body-5-line-height,
  timepicker-cell-active-background-color: color-primary-100,

  timepicker-cell-focus-text-color: color-primary-200,

  // toggles
  toggle-width: 48px,
  toggle-height: 18px,
  toggle-switcher-size: 24px,
  toggle-border-width: 0,
  toggle-basic-background-color: color-basic-400,
  toggle-basic-border-color: transparent,
  toggle-basic-hover-background-color: color-basic-500,
  toggle-basic-hover-checked-background-color: color-basic-500,
  toggle-basic-hover-border-color: transparent,
  toggle-basic-hover-checked-border-color: color-basic-500,
  toggle-basic-focus-checked-background-color: color-basic-500,
  toggle-basic-focus-checked-border-color: transparent,
  toggle-basic-active-border-color: transparent,
  toggle-basic-active-checked-border-color: transparent,
  toggle-basic-active-background-color: color-basic-500,
  toggle-basic-focus-border-color: transparent,
  toggle-basic-focus-background-color: color-basic-500,
  toggle-basic-checked-switcher-background-color: color-basic-100,

  // radios
  // color-primary-transparent-hover: color-basic-400,
  // color-primary-transparent-hover-border: color-basic-400,
  radio-basic-background-color: color-primary-300,
  radio-basic-border-color: color-primary-200,
  radio-basic-active-border-color: color-primary-200,
  radio-basic-active-background-color: color-primary-300,
  radio-basic-active-inner-circle-color: color-primary-200,
  radio-basic-checked-background-color: color-primary-300,
  radio-basic-checked-border-color: color-primary-200,
  radio-basic-focus-background-color: color-primary-300,
  radio-basic-focus-border-color: color-primary-200,
  radio-basic-focus-checked-border-color: color-primary-200,
  radio-basic-focus-inner-circle-color: color-primary-200,
  radio-basic-hover-background-color: color-primary-300,
  radio-basic-hover-border-color: color-primary-200,
  radio-basic-hover-checked-background-color: color-primary-300,
  radio-basic-hover-checked-border-color: color-primary-200,
  radio-basic-hover-inner-circle-color: color-primary-200,
  radio-text-font-weight: 400,

  // checkboxes
  checkbox-outline-color: transparent,
  checkbox-outline-width: 0px,
  checkbox-border-radius: 0px,
  checkbox-basic-active-checked-background-color: color-primary-300,
  checkbox-basic-active-checked-border-color: color-primary-200,
  checkbox-basic-active-background-color: color-primary-300,
  checkbox-basic-active-border-color: color-primary-200,
  checkbox-basic-active-checkmark-color: color-primary-200,
  checkbox-basic-background-color: color-primary-300,
  checkbox-basic-border-color: color-primary-200,
  checkbox-basic-hover-background-color: color-primary-200,
  checkbox-basic-hover-border-color: color-primary-200,
  checkbox-basic-hover-checked-background-color: color-primary-200,
  checkbox-basic-hover-checked-border-color: color-primary-200,
  checkbox-basic-hover-checkmark-color: color-primary-300,
  checkbox-basic-checked-background-color: color-primary-300,
  checkbox-basic-checked-border-color: color-primary-200,
  checkbox-basic-checked-checkmark-color: color-primary-200,
  checkbox-basic-focus-background-color: color-primary-300,
  checkbox-basic-focus-border-color: color-primary-200,
  checkbox-basic-focus-checked-background-color: color-primary-300,
  checkbox-basic-focus-checked-border-color: color-primary-200,
  checkbox-basic-focus-checkmark-color: color-primary-200,
  checkbox-text-font-weight: 400,


  // buttons
  button-outline-color: color-info-500,
  // filled primary == primary button
  button-rectangle-border-radius: 0px,
  button-filled-border-width: 1px 1px 4px 1px,
  button-filled-text-transform: none,
  button-filled-primary-active-background-color: color-basic-active,
  button-filled-primary-focus-background-color: color-primary-default,
  button-filled-danger-background-color: color-danger-default,
  button-filled-danger-border-color	: color-danger-100,
  button-filled-danger-hover-background-color: color-danger-default,
  button-filled-danger-hover-border-color: color-danger-100,
  button-filled-danger-focus-background-color: color-danger-default,
  button-filled-danger-focus-border-color: color-danger-100,
  button-filled-danger-active-background-color: color-danger-default,
  button-filled-danger-active-border-color: color-basic-active,
  button-filled-primary-disabled-background-color: color-primary-disabled,
  button-filled-primary-disabled-border-color: color-primary-disabled,
  button-filled-primary-disabled-text-color: color-basic-600,

  // filled basic == secondary button
  button-filled-basic-background-color: transparent,
  button-filled-basic-border-color: color-primary-default,
  button-filled-basic-hover-background-color: transparent,
  button-filled-basic-hover-border-color: basic-color-hover-border,
  button-filled-basic-active-background-color: transparent,
  button-filled-basic-active-text-color: color-basic-active,
  button-filled-basic-focus-background-color: transparent,
  button-filled-basic-focus-text-color: color-primary-default,
  button-filled-basic-focus-border-color: color-primary-default,
  button-filled-basic-disabled-background-color: transparent,
  button-filled-basic-disabled-border-color: color-primary-disabled,
  button-filled-basic-disabled-text-color: color-primary-disabled,
  // outline basic == tertiary button
  button-outline-border-color: color-primary-default,
  button-outline-text-color: color-primary-default,
  button-outline-border-width: 0px 0px 4px 0px,
  button-outline-text-transform: none,
  button-outline-focus-inset-shadow-length: none,
  button-outline-basic-background-color: transparent,
  button-outline-basic-border-color: color-primary-default,
  button-outline-basic-text-color: color-primary-default,
  button-outline-basic-hover-background-color: transparent,
  button-outline-basic-hover-text-color: color-primary-default,
  button-outline-basic-hover-border-color: color-primary-default,
  button-outline-basic-active-background-color: transparent,
  button-outline-basic-active-text-color: color-basic-active,
  button-outline-basic-active-border-color: color-basic-active,
  button-outline-basic-focus-background-color: transparent,
  button-outline-basic-focus-text-color: color-primary-default,
  button-outline-basic-focus-border-color: color-primary-default,
  button-outline-basic-disabled-background-color: transparent,
  button-outline-basic-disabled-border-color: color-primary-disabled,
  button-outline-basic-disabled-text-color: color-primary-disabled,
  // ghost == no background / icon button / etc
  button-ghost-background-color: transparent,
  button-ghost-border-color: transparent,
  button-ghost-border-width: 0px,
  button-ghost-basic-hover-background-color: transparent,
  button-ghost-basic-focus-background-color: transparent,
  button-ghost-basic-active-background-color: transparent,
  button-ghost-primary-hover-background-color: transparent,
  button-ghost-primary-focus-background-color: transparent,
  button-ghost-primary-active-background-color: transparent,
  button-ghost-basic-active-text-color: basic-color-hover-border,
  button-ghost-primary-active-text-color: basic-color-hover-border,
  button-ghost-basic-disabled-background-color: transparent,
  button-ghost-basic-disabled-border-color: color-primary-disabled,
  button-ghost-basic-disabled-text-color: color-primary-disabled,
  button-ghost-primary-disabled-background-color: transparent,
  button-ghost-primary-disabled-border-color: color-primary-disabled,
  button-ghost-primary-disabled-text-color: color-primary-disabled,

  // hero basic == hero button e.g. for login
  button-hero-background-color: color-primary-100,
  button-hero-basic-border-color: color-primary-default,
  button-hero-basic-border-style: solid,
  button-hero-basic-border-width: 0px 0px 2px 0px,
  button-hero-text-transform: none,
  button-hero-basic-text-color: text-basic-color,
  button-hero-basic-bevel-color: none,
  button-hero-basic-glow-color: none,
  button-hero-basic-left-background-color: color-primary-100,
  button-hero-basic-right-background-color: color-primary-100,
  button-hero-basic-hover-left-background-color: color-primary-100,
  button-hero-basic-hover-right-background-color: color-primary-100,
  button-hero-basic-active-left-background-color: color-primary-100,
  button-hero-basic-active-right-background-color: color-primary-100,
  button-hero-basic-focus-left-background-color: color-primary-100,
  button-hero-basic-focus-right-background-color: color-primary-100,
  button-hero-basic-disabled-background-color: color-primary-disabled,
  button-hero-basic-disabled-border-color: color-primary-disabled,
  button-hero-basic-disabled-text-color: color-basic-600,

  //Stepper:
  tabset-divider-width: 2px,
  stepper-connector-background-color: color-basic-600,

  stepper-step-text-color: color-basic-600,
  stepper-step-text-font-size: text-heading-6-font-size,
  stepper-step-text-font-weight: 790,
  stepper-step-text-line-height: text-heading-6-line-height,
  stepper-step-active-text-color: color-primary-300,
  stepper-step-completed-text-color: color-primary-200,

  stepper-step-index-border-color: color-basic-600,
  stepper-step-index-completed-text-color: color-primary-200,
  stepper-step-index-active-border-color: color-primary-200,
  stepper-step-index-border-width: 2px,
  stepper-step-index-width: 40px,
  stepper-step-index-completed-background-color: transparent,
  // Toastr
  toastr-border-width:  1px 1px 4px 1px,
  toastr-border-radius: 0px,
  toastr-title-text-font-size: text-heading-6-font-size,
  toastr-title-text-font-weight: text-heading-6-font-weight,
  toastr-title-text-line-height: text-heading-6-line-height,

  toastr-info-border-color: color-primary-200,
  toastr-icon-info-color: color-primary-200,
  toastr-info-background-color: color-primary-300,
  toastr-info-text-color: color-primary-200,

  toastr-success-border-color: color-success-200,
  toastr-icon-success-color: color-success-200,
  toastr-success-background-color: color-primary-300,
  toastr-success-text-color: color-primary-200,

  toastr-warning-border-color: color-warning-200,
  toastr-icon-warning-color: color-warning-200,
  toastr-warning-background-color: color-primary-300,
  toastr-warning-text-color: color-primary-200,

  toastr-danger-border-color: color-danger-200,
  toastr-icon-danger-color: color-danger-200,
  toastr-danger-background-color: color-primary-300,
  toastr-danger-text-color: color-primary-200,

  toastr-destroyable-warning-hover-background-color: color-primary-300,
  toastr-destroyable-danger-hover-background-color: color-primary-300,
  toastr-destroyable-info-hover-background-color: color-primary-300,
  toastr-destroyable-success-hover-background-color: color-primary-300,

  // tooltip
  tooltip-border-color: background-alternative-color-3,
  tooltip-text-font-size: text-body-4-font-size,
  tooltip-text-font-weight: text-body-4-font-weight,
  tooltip-text-line-height: text-body-4-line-height,
  tooltip-padding: 16px,
  tooltip-max-width: none,

  // list
  list-item-divider-width: 1px,
  list-item-divider-style: solid,
  list-item-divider-color: color-primary-transparent-200,
  list-item-font-weight: 430,
  list-item-line-height: 25px,
  list-item-padding: 16px 16px 15px 16px,

  // progress-bar
  progress-bar-border-radius: 0px,
  progress-bar-medium-height: 24px,
  progress-bar-medium-text-font-size: text-body-4-font-size,
  progress-bar-medium-text-line-height: text-body-4-line-height,
  progress-bar-primary-text-color: color-primary-200,
  progress-bar-basic-background-color: color-basic-200,
  progress-bar-basic-filled-background-color: color-primary-100,
  progress-bar-primary-filled-background-color: color-primary-100,

  // popover
  popover-background-color: color-basic-700,
  popover-border-color: color-basic-700, // required for arrow border
  popover-border-width: 0px,
  popover-border-radius: 0px,
  popover-padding: 32px,
  popover-text-color: color-primary-300,
  popover-shadow: 0px 2px 5px 0px var(--color-primary-transparent-500),

  // spinner
  spinner-primary-circle-filled-color: color-primary-200,
  spinner-basic-circle-filled-color: color-primary-200,
  spinner-primary-background-color: color-primary-transparent-100,
  spinner-basic-background-color: color-primary-transparent-100,

), innomotics-light, corporate);

// dark mode
$nb-themes: nb-register-theme((
  color-primary-100: #E1F000,
  color-primary-200: #08191F,
  color-primary-300: #FFFFFF,
  // color-primary-400: #E1F000,
  // color-primary-500: #E1F000,
  // color-primary-600: #E1F000,
  // color-primary-700: #E1F000,
  // color-primary-800: #E1F000,
  // color-primary-900: #E1F000,

  color-basic-100: #E4EAED,
  color-basic-200: #CAD5DA,
  color-basic-300: #B2C1C7,
  color-basic-400: #9AACB4,
  color-basic-500: #657880,
  color-basic-600: #53676F,
  color-basic-700: #2A3B40,
  // color-basic-800: #E1F000,
  // color-basic-900: #E1F000,

  color-success-100: #BAFFA9,
  color-success-200: #01823D,

  color-warning-100: #FFCC49,
  color-warning-200: #FF853F,

  color-error-100: #FF9B8D,
  color-error-200: #CB0E0E,

  outline-color: transparent,
  header-background-color: color-primary-100,

  button-filled-danger-text-color: color-primary-200,
  button-filled-danger-background-color: color-danger-100,
  button-filled-danger-border-color	: color-danger-default,
  button-filled-danger-hover-background-color: color-danger-100,
  button-filled-danger-hover-border-color: color-danger-default,
  button-filled-danger-focus-background-color: color-danger-100,
  button-filled-danger-focus-border-color: color-danger-default,
  button-filled-danger-active-background-color: color-danger-100,
  button-filled-danger-active-border-color: color-basic-active,

), innomotics-dark, dark);

// $nb-themes: nb-register-theme(
//   (
//     // Default Font Settings:
//     siemens-font: unquote('SiemensSans, sans-serif'),
//     font-family-primary: siemens-font,
//     font-family-secondary: unquote('SiemensSans, sans-serif'),
//     layout-padding-top: 2.25rem,
//     menu-item-icon-margin: 0 0.5rem 0 0,
//     card-height-tiny: 13.5rem,
//     card-height-small: 21.1875rem,
//     card-height-medium: 28.875rem,
//     card-height-large: 36.5625rem,
//     card-height-giant: 44.25rem,
//     card-margin-bottom: 1.875rem,
//     card-header-with-select-padding-top: 0.5rem,
//     card-header-with-select-padding-bottom: 0.5rem,
//     select-min-width: 6rem,
//     slide-out-background: #f7f9fc,
//     slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
//     slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3
//   ),
//   default,
//   default
// );

// $nb-themes: nb-register-theme(
//   (
//     // Default Font Settings:
//     siemens-font: unquote('SiemensSans, sans-serif'),
//     font-family-primary: siemens-font,
//     font-family-secondary: unquote('SiemensSans, sans-serif'),
//     layout-padding-top: 2.25rem,
//     menu-item-icon-margin: 0 0.5rem 0 0,
//     card-height-tiny: 13.5rem,
//     card-height-small: 21.1875rem,
//     card-height-medium: 28.875rem,
//     card-height-large: 36.5625rem,
//     card-height-giant: 44.25rem,
//     card-margin-bottom: 1.875rem,
//     card-header-with-select-padding-top: 0.5rem,
//     card-header-with-select-padding-bottom: 0.5rem,
//     slide-out-background: linear-gradient(270deg, #edf1f7 0%, #e4e9f2 100%),
//     slide-out-shadow-color: 0 4px 14px 0 #8f9bb3,
//     slide-out-shadow-color-rtl: 0 4px 14px 0 #8f9bb3
//   ),
//   corporate,
//   corporate
// );

// $nb-themes: nb-register-theme(
//   (
//     // Left-panel constants.
//     left-panel-width: 288px,
//     // Responsive Settings: Size thresholds.
//     screen-xs-max: 768px,
//     // Extra Small devices (mobiles, 320px and up)
//     screen-sm-min: 768px,
//     // Small devices (tablets, 768px and up)
//     screen-md-min: 1250px,
//     // Medium devices (desktops, 992px and up)
//     screen-lg-min: 1500px,
//     // Large devices (large desktops, 1200px and up)
//
//     // Icon
//     icon-success-color: Siemens-Green,
//     icon-danger-color: Siemens-Red,
//     // Scrollbar
//     layout-scrollbar-background-color: Siemens-BrightSand,
//     layout-scrollbar-color: Siemens-DarkSand,
//   siemens,
//   corporate
// );
