@mixin nb-overrides() {

  * {
    font-variant-ligatures: none;
  }

  #basic-asset-placeholder {
    margin: auto;
    margin-top: -10px;
    display: block;
    max-width: 400px;
    height: auto;
    width: auto;
    object-fit: scale-down;
    filter: blur(3px);
  }

  #basic-asset-chart {
    margin: auto;
    height: 100%;
    width: auto;
    object-fit: scale-down;
    filter: blur(3px);
  }

  nb-windows-container {
    overflow-x: hidden !important;
  }

  .filePreviewWindow.minimized {
    margin: 0 0.5rem !important;
    max-width: 30vw !important;
    min-width: 5vw !important;

    .title {
      max-width: 75% !important;
      margin-right: 1rem !important;
    }
  }

  .filePreviewWindow {
    nb-card {
      background-color: var(--color-basic-700);
      color: var(--color-primary-300) !important;

      ::-webkit-scrollbar-thumb {
        background: var(--color-basic-100) !important;
      }
    }

    nb-card-header {
      color: var(--color-primary-300) !important;

      button {
        color: var(--color-primary-300) !important;
      }
    }
  }

  nb-window.filePreviewWindow.minimized>nb-card {
    min-height: unset;
  }

  .thermalImageWindow {
    height: 85%;
    width: 35%;

    nb-card {
      height: 100%;

      nb-card-body {
        display: block;
        margin-left: 16px;
        margin-bottom: 24px;

        nb-overlay-container {
          nb-card-body {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .smartCellDetailViewWindow {
    height: 85%;
    width: 80%;

    nb-card {
      height: 100%;
    }
  }

  nb-route-tabset {

    a[href*="pumpEventDetection"],
    a[href*="blowerMonitoring"] ,
    a[href*="sensor_board"],
    a[href*="smart_cell"],
    a[href*="bearing_health"] {
      nb-icon {
        margin-right: 10px;

        img {
          height: 16px;
        }
      }
    }
  }

  // INNO
  nb-menu.side-menu .menu-item .menu-items li.menu-item {
    border-color: transparent !important;
    border-style: none !important;
    border-width: 0px !important;

    a {
      padding-left: 44px;

      span {
        font-weight: 300;
      }

    }
  }

  // INNO
  nb-menu.side-menu a.active {
    border-left-color: var(--color-primary-200);
    border-left-width: 4px;
    border-left-style: solid;
  }

  nb-sidebar.compacted nb-menu .menu-item a.active:before {
    display: none;
  }

  // arrows at menu items
  nb-menu.side-menu .menu-item,
  nb-menu.context-menu .menu-item {
    nb-icon.expand-state {
      transform: rotate(270deg) !important;
    }

    a[aria-expanded=true] {
      nb-icon.expand-state {
        transform: rotate(180deg) !important;
      }
    }
  }

  nb-menu.context-menu ul.menu-items.collapsed {
    nb-icon[ng-reflect-config="chevron-right-outline"] {
      display: none;
    }
  }

  // INNO
  button:not(.appearance-ghost, .select-button, nb-button-group button, .dot) {
    height: 48px !important;
    padding: 12px 24px !important;
    min-width: 95px !important;
    max-width: 280px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    margin-left: 8px !important;
    margin-right: 8px;
  }

  nb-button-group button {
    height: 40px;
    margin: 0px !important;
    background-color: transparent !important;
    border-width: 1px !important;
    border-radius: 0px !important;
    border-color: var(--color-basic-200) !important;
    color: var(--color-basic-600) !important;
    font-size: var(--text-body-4-font-size) !important;
    font-weight: var(--text-body-4-font-weight) !important;
    font-weight: 800 !important;
    line-height: var(--text-body-4-line-height) !important;

    &.booleanSwitch {
      border-color: var(--color-primary-200) !important;
      color: var(--color-primary-200) !important;
      padding: 9px 16px;
      border-width: 2px !important;
    }
  }

  nb-button-group.iconOnly button {
    width: 40px;

    &:hover img {
      transform: scale(1.334);
      animation-duration: 300;
    }
  }

  nb-button-group button:hover {
    color: var(--color-basic-700) !important;

    &.booleanSwitch {
      color: var(--interaction-hover) !important;
      border-color: var(--interaction-hover) !important;
    }
  }

  nb-button-group button:active,
  nb-button-group button[ng-reflect-pressed=true],
  nb-button-group button[aria-pressed=true] {
    background-color: var(--color-basic-200) !important;
    border-color: var(--color-basic-400) !important;
    color: var(--color-primary-200) !important;

    &.booleanSwitch {
      background-color: var(--color-primary-200) !important;
      border-color: var(--color-primary-200) !important;
      color: var(--color-primary-300) !important;

      nb-badge {
        color: var(--color-primary-200) !important;
        background-color: var(--color-primary-100) !important;
      }
    }
  }

  button.placeholder {
    opacity: 1;
  }

  button.appearance-filled.status-primary:hover:not([disabled]),
  button.appearance-filled.status-primary:active:not([disabled]),
  button.appearance-filled.status-primary:focus:not([disabled]) {
    border-width: 0px 0px 8px 0px;
  }

  button.appearance-filled.status-basic:hover:not([disabled]),
  button.appearance-filled.status-basic:active:not([disabled]),
  button.appearance-filled.status-basic:focus:not([disabled]) {
    border-width: 1px 1px 8px 1px;
  }

  button.appearance-outline.status-basic:hover:not([disabled]),
  button.appearance-outline.status-basic:active:not([disabled]),
  button.appearance-outline.status-basic:focus:not([disabled]) {
    border-width: 0px 0px 8px 0px;
  }

  button:focus:not(.appearance-ghost, [disabled]) {
    box-shadow: 0 0 0 0 !important;
    outline: 2px solid var(--color-info-500) !important;
    outline-offset: 2px !important;
  }

  button:focus[disabled] {
    box-shadow: 0 0 0 0 !important;
  }

  button.appearance-ghost:focus {
    box-shadow: 0 0 0 0 !important;
  }

  button.appearance-hero.status-basic {
    border: solid !important;
    box-shadow: 0 0 0 0 !important;
    border-width: 0px 0px 2px 0px !important;
  }

  button.appearance-filled.status-danger {
    border-width: 0px 0px 2px 0px !important;
  }

  button.appearance-filled.status-danger:hover:not([disabled]),
  button.appearance-filled.status-danger:active:not([disabled]),
  button.appearance-filled.status-danger:focus:not([disabled]) {
    border-width: 0px 0px 8px 0px !important;
  }

  button.appearance-hero.status-basic:hover:not([disabled]),
  button.appearance-hero.status-basic:active:not([disabled]),
  button.appearance-hero.status-basic:focus:not([disabled]) {
    border: solid !important;
    box-shadow: 0 0 0 0 !important;
    border-width: 0px 0px 8px 0px !important;
  }

  button.appearance-hero.status-basic:active:not([disabled]) {
    border-color: var(--color-basic-active) !important;
  }

  button.appearance-filled.status-basic:hover // --------------------------------

  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;
    background-color: var(--action-icon-color);

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-select {
    min-width: 150px;
  }

  nb-select.with-placeholder {
    position: relative;

    &.size-medium button {
      height: 3rem;

      span {
        font-size: var(--text-body-4-font-size) !important;
        position: absolute;
        bottom: 0;
        left: 0.7rem;
        overflow: hidden;
        max-width: 80%;
        text-overflow: ellipsis;
      }
    }
  }

  /* in style */
  .route-tabset li {
    text-wrap: nowrap;

    nb-icon {
      position: absolute;
      left: 10px;
    }
  }

  nb-popover {

    .text,
    .header {
      color: var(--color-primary-300) !important;
    }
  }

  /* in style */
  .chart-legend .legend-label-text {
    line-height: var(--list-item-line-height) !important;
  }

  /* in style */
  span.toggle-switcher {
    box-shadow: -2px 0px 2px var(--color-basic-100);
    top: -4px;
  }

  /* in style */
  nb-toggle .toggle.checked .toggle-switcher {
    right: -2px !important;
  }

  nb-toggle .toggle:not(.checked) .toggle-switcher {
    right: initial !important;
  }

  /* in style */
  nb-radio span.inner-circle:hover {
    background-color: var(--color-primary-200) !important;
  }

  /* in style */
  nb-radio span.outer-circle:focus {
    outline: 2px solid var(--color-info-500) !important;
    outline-offset: 2px !important;
  }

  /* in style */
  nb-checkbox span.custom-checkbox:hover {
    background-color: var(--color-primary-200);

    &.checked nb-icon svg {
      color: var(--color-primary-300);
    }
  }

  /* in style */
  nb-popover {
    nb-checkbox {
      span.custom-checkbox {
        background-color: transparent !important;
        &.checked {
          nb-icon svg {
            color: var(--color-primary-300);
          }
        }

        &:hover,
        &:focus {
          background-color: var(--color-primary-300) !important;

          &.checked {
            nb-icon svg {
              color: var(--color-primary-200) !important;
            }
          }
        }
      }
    }
  }

  /* in style */
  nb-checkbox span.custom-checkbox:focus {
    outline: 2px solid var(--color-info-500) !important;
    outline-offset: 2px !important;
  }

  /* in style */
  nb-option:not(.multiple) {
    display: flex;
    justify-content: space-between;
  }

  /* in style */
  nb-option.selected:after {
    content: '\2713';
  }

  /* in style */
  nb-option.multiple {
    &.selected {
      background-color: var(--option-selected-hover-background-color);
      color: var(--option-selected-hover-text-color);

      &:after {
        content: ''
      }
    }
  }

  /* in style */
  nb-toggle div.checked {
    nb-icon {
      display: none;
    }
  }

  /* in style */
  app-search-input nb-form-field:focus {
    outline: 2px solid var(--color-info-500) !important;
    outline-offset: 2px !important;
  }

  /* in style */
  .nb-form-field-prefix-medium,
  .nb-form-field-suffix-medium {
    padding-bottom: 1px;
  }

  /* in style */
  input {
    border: 1px solid var(--color-basic-400) !important;

    &:focus {
      outline: 2px solid var(--color-info-500) !important;
      outline-offset: 2px !important;
    }
  }

  /* in style */
  input:not([disabled]) {

    &:focus,
    &:hover,
    &:focus:hover,
    &:active,
    &:active:hover {
      border: 2px solid var(--color-basic-input-hover-border) !important;
    }
  }

  /* in style */
  input.status-danger {
    border: 2px solid var(--color-danger-default) !important;
    &:focus:hover,
    &:hover,
    &:active,
    &:active:hover {
      border: 2px solid var(--color-danger-default) !important;
    }
  }

  /* in style */
  input.status-success {

    &:focus:hover,
    &:hover,
    &:active,
    &:active:hover {
      border: 2px solid var(--color-success-default) !important;
    }
  }

  /* in style */
  nb-option-list {
    transform: translateY(-10px) !important;

    nb-option {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  app-menu-button-container {
    position: fixed;
    align-items: initial;
    z-index: 0;
  }

  .operating-point-body {
    .chart-contextRect {
      display: none;
    }

    .chart-xAxisContext {
      display: none;
    }

    app-menu-button-container {
      position: fixed;
      align-items: initial;
      z-index: 0;
    }

    .chart-x.chart-brush {
      display: none;
    }

    #context-0 {
      display: none;
    }

    nb-card {
      height: 100%;
      margin-top: 2rem;

      nb-card-body {
        overflow: hidden;

        div {
          display: flex;

          svg {
            // width: 100%;
            // align-self: stretch;
          }
        }
      }
    }
  }


  // hide scrollbars - keep scrolling functionality:
  .element::-webkit-scrollbar {
    width: 0 !important;
  }

  .element {
    overflow: -moz-scrollbars-none;
  }

  .element {
    -ms-overflow-style: none;
  }

  [id^='fingerprint'] {
    svg text {
      font-size: 1rem;
    }
  }

  .diagram-container {
    width: 100%;
    height: 100%;
  }

  .stretch-container {
    width: 100%;
    padding-bottom: 75%;
    position: relative;
  }

  .chart-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  nb-layout-column.asset-detail-navigation {
    padding-top: 5px !important;
    padding-left: 30px !important;
  }

  nb-layout-column.left {
    padding: 0 !important;
    max-width: 300px;
    margin-right: 0.8rem;
  }

  nb-layout-column.chart-column {
    padding: 0 !important;
  }

  // nb-layout-header nav {
  //   height: 3.75rem;
  //   padding: 0.75rem;

  //   // display: inline-flex !important;
  //   // width: 100% !important;
  //   // justify-content: space-between !important;
  // }

  // nb-layout-header.fixed~.layout-container {
  //   padding-top: 3.75rem;
  // }

  button {
    .menu-button.appearance-filled.size-tiny.status-primary.shape-rectangle.icon-start.icon-end.nb-transition {
      background-color: white;
    }
  }

  .card {
    border: none !important;
  }

  nb-accordion-item-header {
    p {
      font-size: 16px;
      font-weight: 600;
      margin-right: 16px;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden !important;
      white-space: nowrap;
    }
  }

  nb-accordion-item.expanded {

    // INNO
    nb-accordion-item-header {
      border-color: var(--color-primary-200);
      border-width: 2px;
    }

    nb-accordion-item-body {
      background-color: var(--color-primary-300) !important;
    }

    border-color: var(--color-primary-200);
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }

  nb-accordion-item:hover {
    // INNO
    background-color: var(--color-primary-transparent-100);
  }

  nb-card {
    // INNO
    align-self: flex-start;
    min-height: 200px;
    padding-top: 24px;
    padding-bottom: 32px;

    nb-card-header {
      text-overflow: ellipsis;
      overflow: hidden !important;
      white-space: nowrap;
      padding-top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:has(app-time-period),
      &:has(app-chart-subset-link) {
        display: block;
      }
    }

    nb-card-body {
      display: block;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    nb-card-footer {
      padding-bottom: 0px;
    }
  }

  .health-condition {

    .chart-xAxisContext,
    .chart-contextRect,
    .chart-brush,
    #context-0 {
      display: none !important;
    }
  }

  .overview-body {
    margin-top: 10px !important;
  }

  nb-badge {
    padding: 0.15rem 0.3rem;
  }

  nb-badge.position-right {
    right: 10px;
  }

  img.asset-image {
    max-width: 100px;
    max-height: 100px;
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  p.asset-path {
    font-size: 0.8rem;
  }

  .ngx-asset-path {
    padding: 0;
    padding-top: 1rem;
  }

  .fp-selection--right {
    padding: 0 !important;
    padding-top: 2.3rem !important;
  }

  .search-result-container {
    box-shadow: 0 0.3rem 0.3rem 0 rgba(44, 51, 73, 0.3);
  }

  ngx-search-input {
    z-index: 100;
    box-shadow: 0 0.3rem 0.3rem 0 rgba(44, 51, 73, 0.3);
    height: 50px;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid lightgray;

    & input {
      height: 100% !important;
      width: 100% !important;
      margin: 0 !important;
    }
  }

  .column-curtain::before {
    background-color: aliceblue;
  }

  table {
    // font-size: 0.7rem;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  .route-tabset,
  .tabset {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  // layout tabs
  app-overview,
  app-charts-component,
  app-operating-point,
  app-asset-log,
  app-asset-reports,
  app-asset-info,
  app-main,
  app-partial-discharge,
  app-asset-tab-container {

    height: 100%;
    width: 100%;
    display: inline-flex;
    flex-direction: column;

    app-asset-details-container {
      position: fixed;
      margin-left: 70px;
    }

    .tab-container {
      height: -webkit-fill-available;
      margin-top: 40px;
      margin-left: 70px;

      >nb-route-tabset,
      >nb-tabset {

        >ul {
          position: sticky;
        }

        // layout subtabs
        app-overview-tab,
        app-charts-tab,
        app-operational-efficiency-tab,
        app-operational-efficiency-drive-tab,
        app-operational-efficiency-iq-tab,
        app-health-condition-analysis,
        app-maintenance-efficiency-tab,
        app-energy-performance,
        app-cooling-tab,
        app-sensor-board-tab,
        app-motor-health-analysis,
        app-operating-point-tab,
        app-fingerprint-tab,
        app-asset-reports-tab,
        app-asset-info-tab,
        app-asset-log-tab,
        app-accumulator-tab,
        app-bearings-tab,
        app-brake-tab,
        app-vibration-overview-tab,
        app-air-gap-tab,
        app-fingerprint-overview-tab,
        app-shift-overview,
        app-recommended-actions,
        app-spare-parts,
        app-general-information,
        app-configuration,
        app-health-condition-analysis-iq,
        app-smart-cell-tab,
        app-spectra-container,
        app-partial-discharge-tab,
        app-partial-discharge-overview-tab,
        app-partial-discharge-3pard-tab,
        app-partial-discharge-prpd-tab,
        app-recommended-actions,
        app-pump-event-detection,
        app-blower-monitoring
        {

          height: -webkit-fill-available;
          width: -webkit-fill-available;
          position: fixed;
          overflow-y: auto !important;
          overflow-x: hidden;
          padding: 0px 20px 20px 0px;
          scrollbar-gutter: auto;
        }

        // cards
        app-overview-tab,
        app-shift-overview {
          nb-card {
            height: 34vh;
            min-height: 300px;
            max-height: 450px;
          }
        }

        app-operational-efficiency-tab,
        app-operational-efficiency-drive-tab,
        app-operational-efficiency-iq-tab {
          nb-card {
            height: 40vh;
            min-height: 300px;
            max-height: 450px;
          }
        }
      }
    }
  }

  app-main .tab-container {
    margin-top: 0px;
    margin-left: 0px;

    nb-card-body {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  // Scrollbar
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-background-color);
  }

  ::-webkit-scrollbar-corner {
    background: var(--scrollbar-background-color);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-color);
  }

  nb-route-tabset ul:not(.prominent-bearing),
  nb-tabset ul:not(.prominent-bearing) {
    // INNO
    margin-bottom: 10px !important;
    align-items: end;

    li>a::before {
      border-radius: 0px !important;
    }

    li.active>a::before {
      height: 6px !important;
    }

    li>a:hover::before {
      height: 6px !important;
    }
  }

  td {
    // border: 1px solid lightgray;
    // width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 6px;
    padding-left: 0px;

    &:first-of-type {
      width: 66%;
    }
  }

  table.asset-data-table {
    line-height: 1rem;
  }

  //inno
  nb-tooltip {
    $arrow-size: 7px;

    .arrow {
      border-bottom: $arrow-size var(--tooltip-border-style) var(--tooltip-background-color);
    }
  }

  .filter-input {
    max-width: 100% !important;
    line-height: 0.8 !important;
  }

  .fp-selection--right {
    padding: 0 !important;
    padding-top: 2.3rem !important;
  }

  .legend-wrap {
    .legend-labels {
      background: transparent;
    }
  }

  .advanced-pie-legend .legend-items-container .legend-items {
    display: flex;

    @media (min-width: 768px) {
      flex-wrap: wrap;
    }
  }

  .advanced-pie-legend .total-value {
    font-size: 32px !important;
  }

  .advanced-pie-legend .total-label {
    font-size: 10px !important;
  }

  .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-label {
    font-size: 12px;
    width: 7vw;
  }

  .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-color {
    height: 50px;
  }

  .advanced-pie-legend .legend-items-container .legend-items .legend-item {
    margin-bottom: 10px;
  }

  .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent {
    font-size: 11px !important;
  }

  .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
    font-size: initial !important;
    margin-top: initial !important;
  }

  #operating-hours {
    margin-left: -1.5rem;

    .y-axis.left-axis {
      visibility: hidden;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
    height: 1.25rem;
  }


  nb-base-calendar {

    // INNO
    nb-card {
      width: fit-content !important;
      padding: 0px !important;

      nb-card-header {
        border-color: var(--color-primary-300) !important;
        border-bottom-style: solid !important;
        border-bottom-width: 1px !important;
      }

      nb-card-header.calendar-navigation {
        position: relative;

        nb-calendar-view-mode {
          position: absolute;
          left: 32%;
        }

        nb-calendar-pageable-navigation {
          width: 100%;
          display: inline-flex;
          justify-content: space-between;
        }
      }
    }



    nb-calendar-range-day-cell {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }

    nb-calendar-day-cell {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }

    nb-calendar-day-cell.selected .cell-content {
      background-color: var(--color-primary-100) !important;
      border-color: var(--color-primary-100) !important;
      color: var(--color-primary-200) !important;
    }

    nb-calendar-day-cell.selected .cell-content:hover {
      background-color: var(--color-active-100) !important;
      border-color: var(--color-active-100) !important;
    }

    nb-calendar-range-day-cell.selected .cell-content:hover {
      background-color: var(--color-basic-200) !important;
      border-color: var(--color-primary-200) !important;
      color: var(--color-primary-200) !important;
    }

    nb-calendar-range-day-cell.start.range-cell {
      .cell-content {
        background-color: var(--color-primary-100) !important;
        border-color: var(--color-primary-100) !important;
        color: var(--color-primary-200) !important;
      }

      .cell-content:hover {
        background-color: var(--color-active-100) !important;
        border-color: var(--color-active-100) !important;
      }
    }

    nb-calendar-range-day-cell.end.range-cell {
      .cell-content {
        background-color: var(--color-primary-100) !important;
        border-color: var(--color-primary-100) !important;
        color: var(--color-primary-200) !important;
      }

      .cell-content:hover {
        background-color: var(--color-active-100) !important;
        border-color: var(--color-active-100) !important;
      }
    }
  }

  nb-card.nb-timepicker-container {
    // INNO
    background-color: var(--color-basic-700) !important;

    nb-calendar-actions>button {
      width: 95%;
      background-color: var(--color-primary-300) !important;
      color: var(--color-primary-200) !important;
      border-width: 0px !important;
      border-style: none !important;
      font-size: var(--text-body-3-font-size) !important;
      font-weight: 790;
    }

    nb-list-item {
      margin-left: 16px;
      margin-right: 16px;
    }

    nb-list-item.selected:hover {
      background-color: var(--color-active-100) !important;
      border-color: var(--color-active-100) !important;
    }
  }

  a[title='spacer'] {
    visibility: hidden;
  }

  .ohlc-chart-chart-health-grade #brush-area-chart-health-grade {
    display: none;
  }

  ngx-overview,
  ngx-operational-efficiency {
    min-width: 100%; // for firefox
  }

  #chart-state-trend {
    width: 90% !important;
  }

  .state-group small.csValue {
    margin: 0 !important;
  }

  ngx-reports nb-list-item {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .state-trend {
    overflow: hidden;
  }

  .timeSelectorDialog {
    position: absolute !important;
    top: 70px !important;
    right: 1.5vw !important;
  }

  @keyframes highlight-animation {
    from {
      background-color: transparent;
      border-color: transparent;
      border-width: 0px;
    }

    to {
      // background-color: rgba(175, 228, 253, 0.7);
      background-color: #e0f1f4;
      border-color: #73bac9;
      border-width: 3px;
    }
  }

  .highlightElement {
    border-width: 3px;
    border-style: solid;
    animation: 0.25s linear;
    animation-name: highlight-animation;
    animation-iteration-count: 4;
    animation-direction: alternate;
  }

  #cursorPopover {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    filter: none !important;
    margin: 0px;

    nb-card-header {
      color: white;
      padding: 10px;
    }

    nb-card-body {
      padding: 10px;
      padding-top: 0px;
    }

    nb-list-item {
      color: white;
      padding: 0px;
      border-width: 0px;
    }
  }

  nb-stepper .step.selected .label-index {
    // INNO
    background-color: var(--color-primary-200);
  }

  nb-stepper .step.selected .label,
  nb-stepper .step.completed .label {

    // INNO
    >span {
      color: var(--color-primary-200) !important;
    }
  }

  nb-stepper>.header>.connector {
    // INNO
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  nb-toast:after {
    // INNO
    content: '\2716';
    align-self: start;
  }

  nb-toast {

    // INNO
    .content-container {
      width: calc(100% - 24px);
    }

    >div.icon-container {
      align-self: start;

      >nb-icon {
        width: 32px;
      }
    }
  }

  nb-popover {
    overflow: scroll;
  }

  nb-dialog-container:not(:has(app-smart-cell-detail-view)):not(:has(.selection-row)):not(:has(app-multi-asset-timeseries-configuration)),
  nb-overlay-container:not(:has(app-smart-cell-detail-view)):not(:has(.selection-row)):not(:has(app-multi-asset-timeseries-configuration)) {

    overflow: auto;

    nb-card-header,
    nb-card-body,
    nb-card-footer {
      color: var(--color-primary-300) !important;
    }

    p {
      color: var(--color-primary-300);
    }

    input:not([disabled]) {
      background-color: var(--color-basic-700) !important;
      color: var(--color-primary-300) !important;
      border-bottom: 1px solid var(--color-primary-300) !important;
      font-size: var(--text-body-4-font-size) !important;
      text-overflow: ellipsis !important;
    }

    input.status-basic:disabled,
    textarea.status-basic:disabled {
      background-color: rgba(255, 255, 255, 0.1) !important;
      border-color: var(--color-basic-400) !important;
      color: var(--color-primary-300) !important;
    }

    app-asset-details-info app-input div input.form__field,
    app-asset-details-info app-input div textarea.form__field {
      padding-top: 28px !important;
    }

    // buttons in dark dialogs
    button.appearance-filled.status-primary:not(.appearance-ghost, .select-button, nb-button-group button) {
      background-color: var(--color-primary-100) !important;
      color: var(--color-primary-200) !important;
      border-color: var(--color-primary-300) !important;
    }

    button.appearance-filled.status-basic:not(.appearance-ghost, .select-button, nb-button-group button) {
      border-color: var(--color-primary-300) !important;
      color: var(--color-primary-300) !important;
    }

    button.appearance-filled.status-primary:hover:not([disabled], nb-button-group button),
    button.appearance-filled.status-primary:active:not([disabled]),
    button.appearance-filled.status-basic:active:not([disabled]),
    button.appearance-filled.status-basic:hover:not([disabled], nb-button-group button) {
      color: var(--color-primary-200) !important;
      background-color: var(--color-basic-300) !important;
      border-width: 1px 1px 8px 1px;
    }

    button.appearance-outline.status-basic {
      border-color: var(--color-primary-300) !important;
      color: var(--color-primary-300) !important;
    }

    button.appearance-outline.status-basic:hover:not([disabled]),
    button.appearance-outline.status-basic:active:not([disabled]) {
      border-color: var(--color-basic-300) !important;
      color: var(--color-basic-300) !important;
    }

    nb-select.size-medium button {
      background-color: transparent !important;
      border-style: none !important;
    }

    nb-select {
      min-width: 150px;
      border-style: solid;
    }

    nb-select:hover:not([disabled]),
    nb-select:active:not([disabled]),
    nb-select:focus:not([disabled]) {
      border-color: var(--color-primary-100);
    }

    nb-checkbox.status-basic .custom-checkbox {
      background-color: transparent;
      border-color: var(--color-primary-300) !important;
    }

    nb-radio.status-basic .native-input:enabled+.outer-circle {
      background-color: transparent !important;
      border-color: var(--color-primary-300) !important;
    }

    nb-radio.status-basic .native-input:enabled:checked+.outer-circle {
      background-color: transparent !important;
      border-color: var(--color-primary-100) !important;
    }

    nb-radio.status-basic .native-input:enabled:checked~.inner-circle {
      background-color: var(--color-primary-100) !important;
    }

    nb-radio.status-basic .text {
      color: var(--color-primary-300) !important;
    }

    app-filter-container p {
      color: var(--color-primary-300) !important;
    }

    nb-base-calendar {
      nb-card {
        background-color: var(--color-basic-700);
      }
    }

    app-time-range-selector {
      display: block;
      height: 100%;
    }

    nb-card {
      background-color: var(--color-basic-700);
      height: 100%;

      >nb-card-header * {
        color: var(--color-primary-300);
        font-size: var(--text-heading-5-font-size);
        font-weight: var(--text-heading-5-font-weight);
        line-height: var(--text-heading-5-line-height);

        // nb-select {
        //   background-color: var(--color-basic-700) !important;
        //   border-bottom: 2px solid var(--color-primary-300) !important;
        // }
      }

      >nb-card-body * {
        color: var(--color-primary-300);
        font-size: var(--text-body-3-font-size);
        font-weight: var(--text-body-3-font-weight);
        line-height: var(--text-body-3-line-height);
      }
    }

    nb-button-group button {
      height: 40px;
      margin: 0px !important;
      // background-color: var(--color-basic-700) !important;
      border-width: 1px !important;
      border-radius: 0px !important;
      border-color: var(--interaction-hover) !important;
      color: var(--color-primary-300) !important;
      font-weight: 800;
    }

    nb-button-group button:hover {
      color: var(--color-basic-300) !important;
      border-color: var(--interaction-hover) !important;
    }

    nb-button-group button:active,
    nb-button-group button[ng-reflect-pressed=true],
    nb-button-group button[aria-pressed=true] {
      background-color: var(--color-basic-500) !important;
      border-color: var(--color-basic-500) !important;
      color: var(--color-primary-100) !important;
    }
  }
}

// health condition page annotation overlays
.annotation-label {
  font-weight: 600;
}

.rca-hg-title {
  font-weight: 600;
  display: inline;
}

.grade-change {
  font-style: italic;
  font-size: 0.8rem;
}

.affected-line-header {
  font-weight: 600;
}

app-pie-doughnut-chart-ng {
  .cs-list {
    list-style: none !important;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;

      div {
        margin-right: 0.5rem;
      }

      div.cs-color {
        width: 10px;
        height: 10px;
        border-radius: 50% 50%;
      }

      div.cs-count {
        font-weight: 600;

        .cs-percent {
          font-weight: 100;
          font-style: italic;
        }
      }
    }
  }
}


.dynamic-table-tooltip-multiline-width {
  max-width: 35% !important;
}

.form-group-p-m-0 {
  .form__group {
    padding: 0 !important;
    margin: 0 !important;

    .form__field:focus~.form__label,
    .form__label {
      top: 4px !important;
    }
  }
}

section#operatingStateTrend, section#operatingStateTrendSinamics, section#bpfi {
  app-core-chart-container {
    height: 50px;
    display: block;
  }
}

.logMessageContainer {
  app-core-chart-container {
    background-color: var(--color-basic-200) !important;
    height: 50px;
    display: block;
  }
}

app-gauge-chart-desired-range {
  .chart-container {
    width: 100px;
    height: 200px !important;
  }
}

app-health-grade-card app-gauge-chart .gauge-chart p.state-chart-label-left {
  left: 8% !important;
}
app-health-grade-card app-gauge-chart p.state-chart-label-right {
  right: 10% !important;
}

app-health-grade-card {
  nb-card-header {
    padding-bottom: 0 !important;
  }
}

.placeholder-item {
  border-radius: 4px;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: var(--color-basic-200);
  opacity: 30%;
}

.placeholder-item::before {
  content: '';
  display: block;
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, transparent 0%, var(--color-basic-400) 20%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
  from {
      left: -100%;
  }
  to   {
      left: 100%;
  }
}

