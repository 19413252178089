
// themes - our custom or/and out of the box themes
@import 'themes';
@import '@nebular/theme/styles/globals';
@import './layout';
@import './overrides';


// loading progress bar theme
@import './pace.theme';


// install the framework and custom global styles
@include nb-install() {
  // nebular global styles
  @include nb-theme-global();
  @include nb-overrides();

  // loading progress bar
  @include ngx-pace-theme();
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
